<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-03-31 16:08:25
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-06 17:06:48
-->
<template>
  <div id="myDiv">
    <!-- <el-button type="primary" @click="changeEchart">显示隐藏</el-button>
    <div id="echarts_box"></div> -->
  </div>
</template>

<script>
import dayjs from "dayjs";
import * as echarts from "echarts";
export default {
  components: {},
  props: {},
  data() {
    return {
      echart: true,
      echartDataa: [
        [10.0, 8.04],
        [8.07, 6.95],
        [13.0, 7.58],
        [9.05, 8.81],
        [11.0, 8.33],
        [14.0, 7.66],
        [13.4, 6.81],
        [10.0, 6.33],
        [8.0, 6.33],
        [7.0, 6.33],
      ],
      echartData:[]
    };
  },
  watch: {},
  computed: {},
  methods: {
    changeEchart() {
      let num = parseInt(Math.random()*10)
      this.echartData = this.echartDataa[num]
        console.log(this.echartData)
        this.initEchart()
    },
    //画图
    initEchart() {
      var chartDom = document.getElementById("echarts_box");
      var myChart = echarts.init(chartDom);
      var option = {
        xAxis: {},
        yAxis: {},
        series: [
          {
            symbolSize: 20,
            data: this.echartData,
            type: "scatter",
          },
        ],
      };
      myChart.setOption(option, true);
    },
  },
  created() {},
  mounted() {
    this.initEchart();
  },
};
</script>
<style lang="scss" scoped>
#echarts_box {
  width: 600px;
  height: 400px;
}
</style>